<template>
 <div>
  <v-dialog :value="value" persistent width="800">
    <v-card class="modalCard">
      <v-toolbar flat dark class="main">
        <v-toolbar-title>Exports</v-toolbar-title>
        <v-spacer />
        <v-btn
          text
          color="grey darken-2"
          @click="$emit('close')"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar flat color="toolbar">
        <v-col cols="5">
          <v-text-field
            v-model="filterText"
            label="Filter"
            placeholder="Filter user exports by file name"
            prepend-inner-icon="mdi-filter-outline"
            hide-details="auto"
            background-color="input"
            dense
            outlined
            clearable
          />
        </v-col>
        <v-spacer />
        <v-btn
          color="#0c67a5"
          class="white--text"
          :disabled="loading"
          @click.stop="createUserExport"
          >
          Create Export
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items.sync="filteredUserExports"
        :items-per-page="pageSize"
        :item-class="rowHighlight"
        :loading="loading"
        :height="400"
        ref="my-table"
        dense
        fixed-header
        hide-default-footer
        loading-text="Loading... Please Wait"
        no-data-text="No Exports Available"
        no-results-text="No Matches Found"
        item-key="export_id"
        @click:row="highlighted=$event"
      >
      <template v-slot:[`item.actions`]="{item}">
        <v-icon @click.stop="downloadFile(item.export_id)">
          mdi-download
        </v-icon>
      </template>
      <template v-slot:[`item.created_on`]="{item}">
        {{ formatDateTimeAlt(item.created_on) }}
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <v-toolbar flat color="toolbar">
          <v-spacer></v-spacer>
          <v-col cols="2" style="max-width: 150px">
            <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" v-model="pageSize"
              :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
            </v-select>
          </v-col>
          <v-btn icon large @click="previous" :disabled="disablePrevious" class="mx-2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <small>Page {{ page }}</small>
          <v-btn icon large @click="next" :disabled="disableNext" class="mx-2">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      </v-data-table>
    </v-card>
  </v-dialog>
 </div>
</template>

<script>
// api
import ItemOrder from "@/axios/item_order"
// mixins
import { displayAlert } from "@/mixins/alert"
import { displayHelpers } from "@/mixins/displayHelpers"
import { fullwidth } from "@/mixins/fullwidth"
import { paging } from "@/mixins/paging"

export default {
  name: 'ExportModal',
  mixins: [displayAlert, displayHelpers, fullwidth, paging],
  props: [
    "value",
    "store_id",
    "storeDeliveryDays",
    "ad_id",
    "ad_name",
    "storeParties",
    "meat_grid"
  ],

  data () {
    return {
      userExports: [],
      highlighted: {},
      filterText: '',
      loading: false
    }
  },

  async created () {
    this.pageSize = 25
    this.pageSizes = [25, 50, 100]
    this.getUserExports()
    this.tableChanged = this.tableChange(this.getUserExports)
  },

  methods: {
    async getUserExports() {
      try {
        this.loading = true
        const { data } = await ItemOrder.getUserExports(this.pageSize, this.from)
        if (data) {
          data.map((element) => element.file_name = element.s3_location.split('/')[3])
          this.userExports = data
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },

    getS3LocationFromUrl(url) {
      const truncate_front = url.split('/').slice(3, 7).join('/')
      const s3_location = truncate_front.split('?')[0]
      return decodeURIComponent(s3_location)
    },

    async createUserExport() {
      try {
        this.loading = true

        // Create Suggested Order Grid
        const related_parties = []
        const store_numbers = []
        this.storeParties.forEach(element => {
          related_parties.push(element.id)
          store_numbers.push(Number(element.wholesaler_store_number))
        })
        const { data } = await ItemOrder.createSuggestedOrderGrid(
          this.ad_id,
          this.ad_name,
          related_parties,
          store_numbers,
          this.storeDeliveryDays,
          this.meat_grid
        )

        // Refresh UI
        await this.getUserExports()
        location.href = data
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },

    rowHighlight(item) {
      return item.export_id === this.highlighted.export_id ? 'primary white--text' : ''
    },

    async downloadFile (id) {
      try {
        const { data } = await ItemOrder.getPresignedUrl(id)
        location.href = data.url
      } catch (err) {
        this.handleError(err)
      }
    },
  },

  computed: {
    headers () {
      return [
        { text: 'Name', sortable: true, filterable: true, value: 'file_name', class: 'accent white--text' },
        { text: 'Created On', sortable: true, filterable: true, value: 'created_on', class: 'accent white--text' },
        { text: 'Actions', sortable: false, filterable: false, value: 'actions', class: 'accent white--text' }
      ]
    },

    filteredUserExports ({ filterText }) {
      if (filterText === '' || filterText == null) {
        return this.userExports
      } else {
        return this.userExports.filter((userExport) =>
          userExport.file_name.toLowerCase().includes(filterText.toLowerCase()))
      }
    },

    totalItems () {
      return this.userExports.length
    },

    disablePrevious () {
      return (this.page - 1) * this.pageSize === 0
    },

    disableNext () {
      return this.totalItems < this.pageSize
    },
  }
}
</script>